import {
  ADD_MESSAGE,
  ADD_MESSAGE_ERROR,
  ADD_MESSAGE_SUCCESS,
  CLEAR_QUIZ_QUESTION_RESULT,
  DISPATCH_CLICKED_ITEM,
  GET_DISCUSSION_TRAINING_SESSION,
  GET_DISCUSSION_TRAINING_SESSION_ERROR,
  GET_DISCUSSION_TRAINING_SESSION_SUCCESS,
  GET_MESSAGES,
  GET_MESSAGES_ERROR,
  GET_MESSAGES_SUCCESS,
  GET_PROGRESS,
  GET_PROGRESS_ERROR,
  GET_PROGRESS_SUCCESS,
  GET_QUIZ,
  GET_QUIZ_ERROR,
  GET_QUIZ_SUCCESS,
  LOAD_COURSE,
  LOAD_COURSE_ERROR,
  LOAD_COURSE_SIDEBAR_DATA,
  LOAD_COURSE_SUCCESS,
  LOAD_TRAINING_SESSION,
  LOAD_TRAINING_SESSION_ERROR,
  LOAD_TRAINING_SESSION_SUCCESS,
  PUSH_QUIZ_QUESTION_RESULT,
  SAVE_QUIZ,
  SAVE_QUIZ_ERROR,
  SAVE_QUIZ_SUCCESS,
  UPDATE_PROGRESS,
  UPDATE_PROGRESS_ERROR,
  UPDATE_PROGRESS_SUCCESS
} from "./action-definitions";

// Initial state
const INIT_STATE = {
  errors: false,
  is_loading: false,
  trainingSession: {},
  courseSidebar: {},
  discussions: [],
  messages: { messages: [], originMessages: [] },
  clickedItem: null,
  quiz_question_results: []
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_TRAINING_SESSION:
      return { ...state, errors: [], is_loading: true };

    case DISPATCH_CLICKED_ITEM:
      return {
        ...state,
        clickedItem: { ...action.payload }
      };

    case LOAD_COURSE_SIDEBAR_DATA:
      return {
        ...state,
        courseSidebar: action.payload
      };

    case LOAD_TRAINING_SESSION_SUCCESS:
      return {
        ...state,
        trainingSession: action.payload,
        is_loading: false
      };
    case LOAD_TRAINING_SESSION_ERROR:
      return { ...state, errors: action.payload, is_loading: false };

    case GET_DISCUSSION_TRAINING_SESSION:
      return { ...state, errors: [], is_loading: true };
    case GET_DISCUSSION_TRAINING_SESSION_SUCCESS:
      return {
        ...state,
        discussions: action.payload,
        is_loading: false
      };

    case GET_DISCUSSION_TRAINING_SESSION_ERROR:
      return { ...state, errors: action.payload, is_loading: false };

    case GET_MESSAGES:
      return { ...state, errors: [], is_loading: true };
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload,
        is_loading: false
      };

    case GET_MESSAGES_ERROR:
      return { ...state, errors: action.payload, is_loading: false };

    case ADD_MESSAGE:
      return { ...state, errors: [], is_loading: true };
    case ADD_MESSAGE_SUCCESS:
      const originMessagesState = [...state.messages.originMessages];

      action.payload.parent_message_id === null &&
        originMessagesState.push(action.payload);
      return {
        ...state,
        messages: {
          messages: [...state.messages.messages, action.payload],
          originMessages: originMessagesState
        },
        is_loading: false
      };

    case ADD_MESSAGE_ERROR:
      return { ...state, errors: action.payload, is_loading: false };

    case GET_PROGRESS:
      return { ...state, errors: [], is_loading: true };
    case GET_PROGRESS_SUCCESS:
      return {
        ...state,
        progress: action.payload,
        is_loading: false
      };
    case GET_PROGRESS_ERROR:
      return { ...state, errors: action.payload, is_loading: false };

    case UPDATE_PROGRESS:
      return { ...state, errors: [], is_loading: true };
    case UPDATE_PROGRESS_SUCCESS:
      const newProgress = state.progress.progress_details.filter(
        p => p.id !== action.payload
      );
      const changedProgress = state.progress.progress_details.find(
        p => p.id === action.payload
      );
      changedProgress.element_status = 1;
      return {
        ...state,
        progress: {
          ...state.progress,
          progress_details: [...newProgress, changedProgress]
        },
        is_loading: false,
        trainingSession: {
          ...state.trainingSession,
          progress: state.trainingSession.progress + 1
        }
      };
    case UPDATE_PROGRESS_ERROR:
      return { ...state, errors: action.payload, is_loading: false };

    case SAVE_QUIZ:
      return { ...state, errors: [], is_loading: true };
    case SAVE_QUIZ_SUCCESS:
      return {
        ...state,
        savedQuiz: action.payload,
        is_loading: false
      };
    case SAVE_QUIZ_ERROR:
      return { ...state, errors: action.payload, is_loading: false };

    case GET_QUIZ:
      return { ...state, errors: [], is_loading: true };
    case GET_QUIZ_SUCCESS:
      return {
        ...state,
        quiz_question_results: action.payload?.question_results ?? [],
        is_loading: false
      };
    case GET_QUIZ_ERROR:
      return { ...state, errors: action.payload, is_loading: false };

    case PUSH_QUIZ_QUESTION_RESULT:
      return {
        ...state,
        quiz_question_results: [...state.quiz_question_results, action.payload]
      };
    case CLEAR_QUIZ_QUESTION_RESULT:
      return { ...state, quiz_question_results: [] };

    case LOAD_COURSE:
      return { ...state, errors: [], is_loading: true };

    case LOAD_COURSE_SUCCESS:
      return {
        ...state,
        trainingSession: action.payload,
        is_loading: false
      };
    case LOAD_COURSE_ERROR:
      return { ...state, errors: action.payload, is_loading: false };

    default:
      return { ...state };
  }
};

export default reducer;
