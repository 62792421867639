import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Question from "../Question";
import "./index.css";
import {
  clearQuizQuestionResult,
  getQuiz,
  pushQuizQuestionResult,
  saveQuiz,
} from "../../redux/participant/participant-course-item/action-creators";

const EtudiantEvaluation = ({ clickedItem, discussions, onClickItem, callbackfn = () => null }) => {
  const [evaluation, setEvaluation] = useState(false);
  const [submitIndex, setSubmitIndex] = useState(0);

  const dispatch = useDispatch();
  const { trainingSession, quiz_question_results } = useSelector(
    (state) => state.participant_course_item
  );
  useEffect(() => {
    setEvaluation(
      trainingSession?.content?.find((c) => c.id === clickedItem?.id)
    );
    setSubmitIndex(0);
  }, [clickedItem]);

  useEffect(() => {
    dispatch(clearQuizQuestionResult());
    if (evaluation?.id)
      dispatch(getQuiz(trainingSession?.training_session?.id, evaluation?.id));
  }, [evaluation]);

  return (
    <div class="NE_etudiant_Evaluation">
      <div class="NE_EVALUATION">
        <div className="NE_notionItem_header">
          <h2 className="NE_notionItem_header_h2">Evaluation</h2>
          {/*{callbackfn()}*/}
        </div>

        {evaluation?.questions?.map((question, index) => {
          const responseQuestion = quiz_question_results?.find(
            (qr) => qr.question_id === question.id
          );
          return (
            <Question
              key={question.id}
              question={question}
              index={index}
              responseQuestion={responseQuestion}
              isSubmit={responseQuestion ? false : index === submitIndex}
              onSubmit={(value) => {
                dispatch(
                  pushQuizQuestionResult({
                    question_id: question.id,
                    question_option_id: value,
                  })
                );

                if (submitIndex === evaluation?.questions?.length - 1) {
                  dispatch(
                    saveQuiz(
                      trainingSession?.training_session?.id,
                      evaluation?.id,
                      [
                        ...quiz_question_results,
                        {
                          question_id: question.id,
                          question_option_id: value,
                        },
                      ]
                    )
                  );
                }
                setSubmitIndex(submitIndex + 1);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
export default EtudiantEvaluation;
